<template>
  <div class="fc-page">
    <div class="fc-page__top">
      <slot v-if="!showOfflineMessage" name="top"></slot>
    </div>

    <div
      v-show="!showOfflineMessage"
      ref="pageContent"
      class="fc-page__content"
      :class="{ 'fc-page__floater': hasFocus && !hideFloatingCard }"
    >
      <ion-content :scroll-events="true" @ionScroll="scrollHandler">
        <template v-if="onAllowedRoutes">
          <ion-refresher id="refresher" slot="fixed">
            <ion-refresher-content />
          </ion-refresher>
        </template>

        <slot></slot>
      </ion-content>

      <floating-card v-if="hasFocus && !hideFloatingCard" />
    </div>

    <div v-if="showOfflineMessage" class="fc-page__content">
      <fc-offline-message />
    </div>

    <div v-show="!showOfflineMessage" class="fc-page__bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
  import NETWORK_STATUS_QUERY from '@/modules/shared/graphql/queries/networkStatus.query.graphql';
  import NAVIGATION_INTENT_QUERY from '@/modules/shared/graphql/queries/navigationIntent.query.graphql';
  import SCROLL_POSITION_QUERY from '@/modules/shared/graphql/queries/scrollPosition.query.graphql';
  import SCROLL_POSITION_MUTATION from '@/modules/shared/graphql/mutations/scrollPosition.mutation.graphql';
  import { computed, defineComponent } from 'vue';
  import FloatingCard from '@/components/floating-card/FloatingCard.vue';
  import { useFocus } from '@/composables/useFocus';
  import { useRoute } from 'vue-router/composables';
  import FcOfflineMessage from './offline-message/OfflineMessage';

  export default defineComponent({
    name: 'FcPage',
    components: {
      FloatingCard,
      FcOfflineMessage,
    },
    props: {
      restoreScrollPositionWithDelay: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['on-refresh'],
    apollo: {
      networkStatus: NETWORK_STATUS_QUERY,
      navigationIntent: NAVIGATION_INTENT_QUERY,
      scrollPosition: {
        query: SCROLL_POSITION_QUERY,
        variables() {
          return {
            path: this.$route.path,
          };
        },
        update: data => data?.scrollPosition?.position ?? 0,
        fetchPolicy: 'cache',
        skip() {
          return !this.savePosition;
        },
      },
    },
    setup() {
      const { hasFocus } = useFocus();
      const route = useRoute();
      const hideFloatingCard = computed(() =>
        ['challenge-detail-no-playlist', 'challenge-detail', 'doing-detail'].includes(route.name)
      );

      return {
        hasFocus,
        hideFloatingCard,
      };
    },
    data: () => ({
      restoreScrollPositionWatcher: undefined,
      scrollPosition: null,
      navigationIntent: false,
      networkStatus: {
        connected: false,
        connectionType: 'offline',
      },
    }),
    computed: {
      savePosition: vm => vm.$route.meta?.savePosition ?? false,
      showOfflineMessage: vm => !vm.networkStatus.connected && vm.navigationIntent,
      onAllowedRoutes() {
        return (
          this.$route.path !== '/' &&
          this.$route.path !== '/login' &&
          this.$route.path !== '/profile' &&
          this.$route.path !== '/foodcoach-ai'
        );
      },
    },
    activated() {
      const ionContent = document
        .querySelector('ion-content')
        ?.shadowRoot.querySelector('.inner-scroll');

      if (this.savePosition && this.scrollPosition && ionContent) {
        ionContent.scrollTop = this.scrollPosition;
      }
    },
    mounted() {
      if (this.onAllowedRoutes) {
        const refresher = document.getElementById('refresher');

        refresher?.addEventListener('ionRefresh', () => {
          this.$emit('on-refresh');
          setTimeout(() => window.location.reload(), 1200);
        });
      }
    },
    methods: {
      scrollHandler() {
        if (!this.savePosition) {
          return;
        }

        const ionContent = document
          .querySelector('ion-content')
          ?.shadowRoot.querySelector('.inner-scroll');

        if (ionContent) {
          this.$apollo.mutate({
            mutation: SCROLL_POSITION_MUTATION,
            variables: { path: this.$route.path, position: ionContent.scrollTop },
          });
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-page {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 0;
    padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0)
      env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
    position: absolute;
    right: 0;
    top: 0;

    &__top,
    &__bottom {
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      z-index: $z-index-page-top-bottom-bar;
    }

    &__content {
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      z-index: $z-index-page-content;
    }

    &__floater .fc-page-content {
      padding-bottom: 4.5rem;
    }
  }

  .no-scroll .fc-page__content {
    overflow-y: hidden;
  }
</style>

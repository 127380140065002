import { nowISOString } from '@/modules/shared/utils/date.util';
import { useChallenge } from '@/composables/useChallenge';

import GET_TRAINING_SESSIONS from '@/modules/planner/training/graphql/queries/trainingSessions.graphql';
import GET_MEAL_TEMPLATE_QUERY from '@/modules/planner/graphql/queries/dayTemplate.query.graphql';

const trainingAndMoments = async apollo => {
  const [trainingSessionsResult, mealTemplateResult] = await Promise.all([
    apollo.query({
      query: GET_TRAINING_SESSIONS,
      variables: {
        startDate: nowISOString(),
      },
      fetchPolicy: 'no-cache',
    }),
    apollo.query({
      query: GET_MEAL_TEMPLATE_QUERY,
      variables: {
        date: nowISOString(),
      },
      fetchPolicy: 'no-cache',
    }),
  ]);

  const primaryTraining = trainingSessionsResult.data.trainingSessions.find(
    training => training.primary
  );
  const moments = mealTemplateResult.data?.getMealTemplate?.meals;
  return { primaryTraining, moments };
};
const openFoodCoachAi = params => {
  const { challengeUuid, router, user } = params;
  const { challenge, fetch: fetchChallenge } = useChallenge(challengeUuid, true);

  return {
    execute: async completed => {
      await fetchChallenge();
      const medalTitle = challenge.value.medal?.title;
      const medalTypes = ['bronze', 'silver', 'gold'];
      const foundType = medalTypes.find(
        type => challenge.value.medal[type]?.challenge?.uuid === challengeUuid
      );

      const activeMedal = challenge.value.medal[foundType] || null;
      const medalName = activeMedal.name;

      const context = `start self assessment for ${medalName} medal in ${medalTitle}. Use language ${user.value.preferredLanguage || 'en'}`;
      router.push({
        name: 'chat',
        query: {
          assistant: 'medal-self-assessment',
          scope: `${challengeUuid}-${medalName}`,
          medalTitle,
          completed,
        },
        params: {
          context,
        },
      });
    },
  };
};

const openFoodDuringTraining = params => {
  const { router, apollo } = params;

  return {
    execute: async () => {
      const { primaryTraining, moments } = await trainingAndMoments(apollo);

      const find = moments?.findIndex(
        meal => meal.trainingId && meal.trainingId === primaryTraining?.id
      );
      const trainingIntake = moments?.at(find);

      if (primaryTraining && primaryTraining.carbsPerHour && trainingIntake) {
        router.push({
          name: 'planner-tune-your-meal',
          params: {
            date: primaryTraining.date,
            moment: `${trainingIntake.moment}-${primaryTraining.id}`,
          },
          query: {
            redirect: router.currentRoute.fullPath,
          },
        });
      } else {
        router.push({ name: 'planner', params: { date: nowISOString() } });
      }
    },
  };
};

function openTrainingMealMoment(indexOffset, params) {
  const { router, apollo } = params;

  return {
    execute: async () => {
      const { primaryTraining, moments } = await trainingAndMoments(apollo);
      const find = moments.findIndex(
        meal => meal.trainingId && meal.trainingId === primaryTraining?.id
      );
      const recoveryMeal = moments.at(find + indexOffset);

      if (recoveryMeal && primaryTraining) {
        const route = {
          name: 'planner-tune-your-meal',
          params: {
            date: primaryTraining.date,
            moment: recoveryMeal.moment,
          },
          query: {
            redirect: router.currentRoute.fullPath,
          },
        };
        await router.push(route);
      } else {
        router.push({ name: 'planner', params: { date: nowISOString() } });
      }
    },
  };
}

const openPlanTrainingPage = params => {
  const { router, user } = params;
  return {
    execute: async () => {
      router.push({
        name: 'planner-add-training',
        params: {
          type: user.value.sportType,
          initialDate: nowISOString(),
        },
      });
    },
  };
};

const openPreTrainingMealMoment = params => openTrainingMealMoment(-1, params);
const openRecoveryMealMoment = params => openTrainingMealMoment(1, params);
export const actionMapping = new Map([
  ['open my plan', { name: 'planner', params: { date: nowISOString() } }],
  ['open performance goal', { name: 'profile-performance-goal' }],
  ['open weight goal', { name: 'profile-weight-goal' }],
  ['open my goal page', { name: 'profile-my-goals' }],
  ['open my nutrition plan metrics', { name: 'profile-my-nutrition-plan-metrics' }],
  ['open profile > connect devices', { name: 'profile-connect-devices' }],
  ['open foodcoach ai', openFoodCoachAi],
  ['open carbs per hour calculator', { name: 'carbs-per-hour-calculator' }],
  ['open plan training page', openPlanTrainingPage],
  ['open pre-training meal moment', openPreTrainingMealMoment],
  ['open recovery meal moment', openRecoveryMealMoment],
  ['open food during training', openFoodDuringTraining],
]);

<template>
  <fc-page-content class="fc-offline-message">
    <jum-heading h5 bold>{{ heading }}</jum-heading>

    <p>{{ explanation }}</p>

    <jum-button
      data-test-id="retry-button"
      :in-progress="connecting"
      aria-live="polite"
      @click="retryConnection"
    >
      {{ buttonText }}
    </jum-button>
  </fc-page-content>
</template>

<script>
  import { JumButton, JumHeading } from '@blancofoodcoach/kompas';
  import NETWORK_STATUS_QUERY from '@/modules/shared/graphql/queries/networkStatus.query.graphql';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcOfflineMessage',
    components: { JumButton, JumHeading },
    apollo: {
      networkStatus: NETWORK_STATUS_QUERY,
    },
    props: {
      heading: {
        type: String,
        default: 'Oeps, de app werkt even niet',
      },
      explanation: {
        type: String,
        default:
          'Het lijkt er op dat er een probleem is met de app. We proberen het zo snel mogelijk voor je op te lossen. Sorry voor het ongemak.',
      },
      buttonText: {
        type: String,
        default: 'Probeer opnieuw',
      },
    },
    data: () => ({
      connecting: false,
    }),
    methods: {
      async retryConnection() {
        this.connecting = true;

        try {
          await this.networkStatus.refresh();
        } catch {
          // eslint-disable-next-line no-console
          console.warn('Failed to retrieve network status');
        } finally {
          // Add delay to make sure the user sees his/her intent
          setTimeout(() => {
            this.connecting = false;
          }, 400);
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .fc-offline-message {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
  }
</style>

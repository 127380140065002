<script>
  import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { JumButton, JumHeading, JumInputField } from '@blancofoodcoach/kompas';
  import { useOpenAIAssistant } from '@/composables/useOpenAIAssistant';
  import { marked } from 'marked';
  import { groupBy } from 'ramda';
  import { format } from 'date-fns';
  import { useI18n } from '@/modules/core/composables/useI18n';
  import TypingLoader from '@/components/chat/TypingLoader.vue';
  import { Capacitor } from '@capacitor/core';
  import { Keyboard } from '@capacitor/keyboard';
  import { noop } from '@vueuse/core';
  import { popovery } from '@/modules/core/plugins/renderlessBuss';
  import AiAssistantPopover from '@/components/popovers/ai-assistant/AiAssistantPopover.vue';
  import FcLink from '@/modules/shared/components/link/Link.vue';
  import NicePopover from '@/modules/shared/components/nice-popovers/NicePopover.vue';
  import ChatMessages from '@/components/chat/ChatMessages.vue';
  import { useVueProxy } from '@/composables/useVueProxy';

  export default defineComponent({
    name: 'AssistantPopover',
    components: {
      ChatMessages,
      NicePopover,
      JumHeading,
      FcLink,
      TypingLoader,
      JumInputField,
      JumButton,
    },
    props: {
      popover: {
        type: Object,
        default: () => ({}),
      },
    },
    setup(props) {
      const { t } = useI18n();
      const { modaly } = useVueProxy();
      const chatComponent = ref(null);
      const newMessage = ref('');

      const { context, assistant, scope, clearOnClose } = props.popover;
      const { thread, addMessage, isResponding, isLoading, isChatUnavailable, deleteChatHistory } =
        useOpenAIAssistant(assistant, scope, context);

      watch(isChatUnavailable, value => {
        if (value) {
          modaly({
            title: t('unavailable.title'),
            message: t('unavailable.description'),
            cancelText: t('unavailable.confirm'),
            onCancel: async modal => {
              modal.close();
              window.location.reload();
            },
          });
        }
      });

      const showSpinner = ref(false);
      const spinner = ref();

      const sortedMessages = computed(() => {
        const messages = [...(thread.value?.messages || [])];
        messages.sort((a, b) => a.createdAtRaw - b.createdAtRaw);
        return messages;
      });

      const lastMessage = computed(() => [...(sortedMessages.value || [])].pop());

      const send = () => {
        if (newMessage.value.trim() === '') {
          return;
        }
        addMessage(newMessage.value);
        newMessage.value = '';
      };

      const doCta = async callToAction => {
        await addMessage(callToAction);
      };

      const getDateLabel = message => {
        const date = format(message.createdAtRaw * 1000, 'dd-MM-yyyy');
        const today = format(new Date(), 'dd-MM-yyyy');

        if (date === today) {
          return t('today');
        }

        return date;
      };
      const groupedMessages = computed(() => {
        const messages = thread.value?.messages || [];

        return Object.entries(
          groupBy(
            getDateLabel,
            [
              ...messages.map(message => {
                let parse;
                try {
                  // Remove the code block markers (```json and ```)
                  const jsonString = message.content[0].text.value
                    .replace(/```json|```/g, '')
                    .trim();

                  // Parse the cleaned JSON string
                  parse = JSON.parse(jsonString);
                } catch (e) {
                  parse = { message: marked.parse(message.content[0].text.value), cta: null };
                }
                const { message: text, cta, completedStep, userInput } = parse;

                if (completedStep && userInput)
                  return {
                    ...message,
                    text: t(`completed.${completedStep}`),
                    completed: true,
                  };
                return {
                  ...message,
                  text: marked.parse(text.replace(/【\d+(:\d+)?†source】/g, '')),
                  cta,
                };
              }),
            ].reverse()
          )
        );
      });

      const placeholder = computed(() => {
        if (isResponding.value) {
          return t('waitingForCoach');
        }

        return t('placeholder');
      });

      const scrollLastMessageIntoView = () => {
        nextTick(() => {
          const messages = document.querySelectorAll('.chat__balloon');
          const last = messages[messages.length - 1];

          if (last) {
            last.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        });
      };

      watch(lastMessage, scrollLastMessageIntoView);
      watch(thread, value => {
        if (value.messages.length > 0) {
          showSpinner.value = false;
        }
      });

      let listener;

      onMounted(async () => {
        if (Capacitor.isNativePlatform()) {
          try {
            listener = await Keyboard.addListener('keyboardDidHide', scrollLastMessageIntoView);
          } catch {
            // eslint-disable-next-line no-console
            console.warn('Failed to register keyboard listeners');
          }
        }
      });

      onUnmounted(async () => {
        if (Capacitor.isNativePlatform()) {
          listener?.remove();
        }
      });

      const openPopover = () => {
        popovery({
          component: AiAssistantPopover,
        });
      };

      const onClose = async () => {
        if (clearOnClose) {
          await deleteChatHistory();
        }
      };

      return {
        onClose,
        thread,
        groupedMessages,
        newMessage,
        showSpinner,
        openPopover,
        send,
        doCta,
        lastMessage,
        spinner,
        chatComponent,
        isResponding,
        isLoading,
        placeholder,
      };
    },
    methods: { noop },
  });
</script>

<template>
  <nice-popover :popover="popover" @close="onClose">
    <template #title>
      <jum-heading h3 class="nice-popover__title">
        <strong>{{ popover.title || $t('title') }}</strong>
      </jum-heading>
    </template>

    <template #top>
      <fc-nav-bar>
        <div class="container">
          <img
            :src="require('@/assets/images/experts/ai-expert.svg')"
            alt="Avatar of FoodCoach AI"
            @click="openPopover"
          />
          {{ $t('title') }}
        </div>
      </fc-nav-bar>
    </template>

    <template #body>
      <main v-if="thread" class="chat">
        <div class="expert-avatar">
          <img
            :src="require('@/assets/images/experts/ai-expert.png')"
            alt="Image of a FoodCoach AI"
          />
        </div>

        <chat-messages v-if="groupedMessages" :grouped-messages="groupedMessages" hide-dates />

        <div v-if="isResponding" class="chat__balloon chat__balloon--other chat__balloon--typing">
          <typing-loader />
        </div>
      </main>
    </template>

    <template #footer>
      <form @submit.prevent="send" @keydown.enter.prevent="noop">
        <jum-input-field
          v-model="newMessage"
          name="question"
          :placeholder="placeholder"
          :disabled="isResponding"
          class="question"
        />
        <jum-button type="submit" :disabled="isResponding">
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.125 6.97872C17.125 7.49744 16.8345 7.97376 16.3834 8.19238C16.2374 8.2539 6.50679 12.2158 2.97249 13.7511C2.78206 13.8351 2.58275 13.875 2.38586 13.875C2.01952 13.875 1.66125 13.7337 1.3756 13.4643C0.924538 13.0371 0.763154 12.3962 0.953587 11.7918C0.976987 11.717 1.01088 11.6463 1.05364 11.5823L2.79739 8.93555C3.04834 8.55482 3.55024 8.45673 3.9182 8.7136C4.28696 8.97212 4.38298 9.48918 4.13203 9.86907L2.67877 12.0753C5.83059 10.7186 12.7071 7.90642 14.9899 6.97456L2.81837 1.91208L5.48604 6.17072H10.3267C10.773 6.17072 11.1336 6.54313 11.1336 7.00199C11.1336 7.46169 10.773 7.83327 10.3267 7.83327H5.04627C4.77192 7.83327 4.51693 7.69029 4.36846 7.45338L1.19001 2.37926C1.14805 2.31275 1.11578 2.24043 1.09399 2.16396C0.922117 1.56377 1.0948 0.933664 1.54586 0.519688C1.98483 0.117349 2.59243 0.0134393 3.13388 0.250354L16.3415 5.74593C16.8361 5.98617 17.125 6.46249 17.125 6.97872Z"
              fill="white"
            />
          </svg>
        </jum-button>
      </form>
      <span class="disclaimer">
        {{ $t('disclaimer') }}
        <fc-link href="#" @click="openPopover()">
          {{ $t('disclaimerLink') }}
        </fc-link>
      </span>
    </template>
  </nice-popover>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .container {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  form {
    display: flex;
    gap: 10px;

    :deep(.jum-input-field) {
      flex-grow: 1;

      &:focus-within .container {
        outline: 0 !important;
      }
    }

    :deep(.jum-button) {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      width: 44px;

      svg {
        flex-shrink: 0;
      }

      &.disabled {
        svg {
          path {
            fill: #757575;
          }
        }
      }
    }
  }

  .chat {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .expert-avatar {
      align-self: center;
    }

    &__balloon {
      background-color: #f1f1f1;
      border-radius: 8px 8px 0;
      font-size: 0.9rem;
      gap: 10px;
      margin: 0 0 0 auto;
      max-width: 70vw;
      padding: 8px 16px;

      &--other {
        background-color: #f4f3f9;
        border-radius: 8px 8px 8px 0;
        margin: 0 auto 0 0;
      }

      &--typing {
        padding: 0;
      }
    }
  }

  .disclaimer {
    color: #9e9e9e;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
  }

  :deep(.fc-page__bottom) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem;

    .jum-button {
      text-align: center;
    }
  }
</style>

<i18n>
{
  "en": {
    "next": "Next",
    "title": "FoodCoach AI",
    "placeholder": "Ask your question",
    "waitingForCoach": "Waiting for coach to answer...",
    "today": "Today",
    "deleteChatHistory": {
      "button": "Delete chat history",
      "title": "Are you sure you want to delete the history of this chat?",
      "message": "This action can’t be restored",
      "confirmText": "Delete",
      "cancelText": "Cancel",
      "completed": "Your chat history has been deleted"
    },
    "unavailable": {
      "title": "FoodCoach AI is currently not availble, please come back later",
      "description": "Also for our AI some recovery is crucial  to improve it’s performance 😴",
      "confirm": "Close chat"
    },
    "disclaimer": "FoodCoach AI can make mistakes.",
    "disclaimerLink": "Check important info"
  },
  "nl": {
    "next": "Volgende",
    "title": "FoodCoach AI",
    "placeholder": "Stel je vraag",
    "waitingForCoach": "Wachten op coach om te antwoorden...",
    "today": "Vandaag",
    "deleteChatHistory": {
      "button": "Geschiedenis verwijderen",
      "title": "Weet je zeker dat je de geschiedenis van deze chat wilt verwijderen?",
      "message": "Deze actie kan niet worden hersteld",
      "confirmText": "Verwijderen",
      "cancelText": "Annuleren",
      "completed": "Je chatgeschiedenis is verwijderd"
    },
    "unavailable": {
      "title": "FoodCoach AI is momenteel niet beschikbaar, kom later terug",
      "description": "Ook voor onze AI is wat herstel cruciaal om de prestaties te verbeteren 😴",
      "confirm": "Chat sluiten"
    },
    "disclaimer": "FoodCoach AI kan fouten maken.",
    "disclaimerLink": "Belangrijke informatie"
  }
}
</i18n>

<script>
  import { defineComponent } from 'vue';
  import { JumButton, JumIcon, JumHeading } from '@blancofoodcoach/kompas';
  import { isEmpty } from 'ramda';
  import { App as CapApp } from '@capacitor/app';
  import { Device } from '@capacitor/device';

  export default defineComponent({
    name: 'NicePopover',
    components: {
      JumButton,
      JumIcon,
      JumHeading,
    },
    props: {
      popover: {
        type: Object,
        default: () => ({
          message: '',
          title: '',
          confirmText: '',
          cancelText: '',
          dismissible: 'true',
        }),
      },
    },
    emits: ['close'],
    data() {
      return {
        listener: null,
      };
    },
    computed: {
      footerVisible: vm => vm.$scopedSlots.footer || vm.popover.onConfirm || vm.popover.onCancel,
      bodyVisible: vm => vm.$scopedSlots.body || !isEmpty(vm.popover.message),
    },
    async mounted() {
      const { platform } = await Device.getInfo();

      if (platform === 'android' && this.popover.dismissible) {
        this.listener = CapApp.addListener('backButton', () => {
          this.popover.close();
        });
      }
    },
    beforeMount() {
      // eslint-disable-next-line vue/no-mutating-props
      if (typeof this.popover.dismissible !== 'boolean') this.popover.dismissible = true;

      const { activeElement } = document;
      if (activeElement) activeElement.blur();
    },
    destroyed() {
      this.listener?.remove();
    },
  });
</script>

<template>
  <section ref="nicePopover" class="nice-popover">
    <header class="nice-popover__header">
      <slot name="title">
        <jum-heading v-if="popover.title" h3 class="nice-popover__title">
          <strong v-html="popover.title"></strong>
        </jum-heading>
      </slot>
      <button
        v-if="popover.dismissible"
        type="button"
        class="nice-popover__close-button"
        data-test-id="buttonClosePopOver"
        @click="
          $emit('close');
          popover.close();
        "
      >
        <jum-icon icon="jum-cross" size="20" />
      </button>
    </header>

    <div v-if="bodyVisible" class="nice-popover__body">
      <slot name="body">
        <div v-if="popover.message" class="nice-popover__message" v-html="popover.message"></div>
      </slot>
    </div>

    <div v-if="footerVisible" class="nice-popover__footer">
      <slot name="footer" :popover="popover">
        <jum-button
          v-if="popover.onConfirm"
          class="nice-popover__footer-button"
          medium
          block
          @click="popover.confirm()"
        >
          {{ popover.confirmText }}
        </jum-button>
        <jum-button
          v-if="popover.onCancel"
          class="nice-popover__footer-button"
          secondary
          medium
          block
          @click="popover.cancel()"
        >
          {{ popover.cancelText }}
        </jum-button>
      </slot>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .nice-popover {
    background: $color-white;
    border: 1px solid $color-gray-lighten-80;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    flex-direction: column;
    height: calc(100% - env(safe-area-inset-top, 0) - 24px);
    overflow: hidden;
    position: fixed;
    width: 100%;

    &__header {
      align-items: flex-start;
      background-color: $color-white;
      display: flex;
      padding: $spacing-m;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: $z-index-reset;
    }

    &__title {
      font-weight: 900;
      margin: 0 $spacing-l 0 0;
    }

    &__close-button {
      background: none;
      border: 0;
      margin-left: auto;
      padding: 0;
      vertical-align: middle;

      svg {
        display: inline-block;
      }
    }

    &__body {
      background: $color-white;
      color: $color-black;
      flex: auto;
      overflow: auto;
      padding: 0 $spacing-m;
    }

    &__footer {
      background: $color-white;
      bottom: 0;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: 0.5rem;
      padding: $spacing-m $spacing-m calc(env(safe-area-inset-bottom, 0) + #{$spacing-m});
      position: sticky;
    }

    &__footer-button:not(:last-child) {
      margin-bottom: $spacing-s !important;
    }
  }
</style>
